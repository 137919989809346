import { Alert, MenuItem, Select, TextField } from '@mui/material'
import { CarePlansColors } from '@utils/constants'
import React, { useEffect, useState } from 'react'
import MeasurementsForm from '../measurement-form/MeasurementsForm'
import PropTypes from 'prop-types'
import 'react-dropdown/style.css'
import styles from './CarePlansForm.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const CarePlansForm = ({ btnFunction, carePlan, portal, fetchAction }) => {
  const [color, setColor] = useState('')
  const [name, setName] = useState('')
  const [requirements, setRequirements] = useState([])
  const [surveys, setSurveys] = useState(null)

  const nonUniqueReqFlag =
    new Set(requirements.map((r) => r.action)).size !== requirements.length

  useEffect(() => {
    if (carePlan) {
      setColor(carePlan.color)
      setName(carePlan.name)
      setRequirements(carePlan.requirements)
    }
  }, [carePlan])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await ClinicvuService.getAllSurveys(portal)
        setSurveys(res.data)
      } catch (e) {
        console.error(
          `Error occurred while fetching surveys for enrolling new patient: ${e}`
        )
      }
    })()
  }, [])

  const addNewRequirement = (e) => {
    e.preventDefault()

    const newRequirement = {
      action: 'bp',
      type: 'none',
      frequency: 1,
      period: 'week',
      normal_range_min: '',
      normal_range_max: '',
      normal_range_min_custom: { sp: '', dp: '' },
      normal_range_max_custom: { sp: '', dp: '' },
      change_time_frame: null,
      change_value: null,
      survey: ''
    }

    const newRequirements = [...requirements, newRequirement]

    setRequirements(newRequirements)
  }

  const removeRequirement = (index) => {
    const newRequirements = [...requirements]
    newRequirements.splice(index, 1)
    setRequirements(newRequirements)
  }

  const handleRequirementChange = (val, index, param) => {
    const newRequirements = [...requirements]
    if (val <= 0) val = 1
    newRequirements[index][param] = val
    setRequirements(newRequirements)
  }

  const handleCustomRequirementChange = (val, index, param, key) => {
    const newRequirements = [...requirements]
    if (val <= 0) val = 1
    newRequirements[index][param][key] = val
    setRequirements(newRequirements)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (name) {
      try {
        await ClinicvuService.createCarePlan(name, color, requirements, portal)
        btnFunction(null, false)
        fetchAction()
      } catch (e) {
        console.error(
          `Error occurred while submitting care plan information: ${e}`
        )
      }
    } else {
      alert('Your care plan must have a name.')
    }
  }

  return (
    <div>
      <div className={styles.header}>
        {carePlan ? 'View Care Plan' : 'New Care Plan'}
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <div>Select a color:</div>
          <Select
            size="small"
            disabled={carePlan !== null}
            value={color}
            style={{
              backgroundColor: color,
              color: 'white',
              marginLeft: '5px',
              width: '100px'
            }}
            onChange={(e) => setColor(e.target.value)}
          >
            {Object.keys(CarePlansColors)?.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {CarePlansColors[item]}
                </MenuItem>
              )
            })}
          </Select>

          <TextField
            label="Care Plan Name"
            disabled={carePlan !== null}
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            style={{ marginLeft: '25px' }}
          />
        </div>

        <div className={styles.requirements}>
          {requirements?.map((requirement, index) => (
            <MeasurementsForm
              key={index}
              requirement={requirement}
              handleRequirementChange={handleRequirementChange}
              handleCustomRequirementChange={handleCustomRequirementChange}
              index={index}
              surveys={surveys}
              removeRequirement={removeRequirement}
              carePlan={carePlan}
            />
          ))}
        </div>

        {nonUniqueReqFlag && (
          <Alert severity="info">
            Please remove or edit all duplicate requirement types
          </Alert>
        )}

        <div className={styles['btns-row']}>
          {!carePlan
            ? (
            <button
              disabled={!!carePlan?.id}
              onClick={addNewRequirement}
              className={`${styles.save__btn} ${styles['no-margin-left']}`}
            >
              Add Requirement
            </button>
              )
            : null}
          <div>
            <button
              className={styles['btn-cancel']}
              onClick={() => btnFunction(null, false)}
            >
              {carePlan ? 'Back' : 'Cancel'}
            </button>
            {!carePlan
              ? (
              <button
                onClick={handleSubmit}
                className={styles.save__btn}
                disabled={nonUniqueReqFlag || !!carePlan?.id}
              >
                Save
              </button>
                )
              : null}
          </div>
        </div>
      </form>
    </div>
  )
}

CarePlansForm.propTypes = {
  btnFunction: PropTypes.func,
  carePlan: PropTypes.object,
  portal: PropTypes.string,
  fetchAction: PropTypes.func
}

export default CarePlansForm
