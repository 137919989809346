import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Alerts from '../components/pages/alerts/Alerts'
import CarePlans from '../components/pages/care-plans/CarePlans'
import Clinic from '../components/pages/clinic/Clinic'
import Home from '../components/pages/home/Home'
import Surveys from '../components/pages/surveys/Surveys'
import PatientDetails from '../components/pages/patient-details/PatientDetails'
import SignOut from '../components/shared/sign-out/SignOut'
import Portals from '@pages/portals/Portals'
import Sessions from '@pages/sessions/Sessions'

const ClinicvuRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Navigate replace to="patients" />} />
      <Route path="/patients" element={<Home />} />
      <Route path="/patient/:patientID" exact element={<PatientDetails />} />
      <Route path="/alerts" exact element={<Alerts />} />
      <Route path="/care-plans" exact element={<CarePlans />} />
      <Route path="/surveys" exact element={<Surveys />} />
      <Route path="/clinic" exact element={<Clinic />} />
      <Route path="/session" exact element={<Sessions />} />
      <Route path="/portals" exact element={<Portals />} />
      <Route path="/sign-out" exact element={<SignOut />} />
    </Routes>
  )
}

export default ClinicvuRoutes
